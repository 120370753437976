import { render, staticRenderFns } from "./Over.vue?vue&type=template&id=5e7f5bde&scoped=true&"
var script = {}
import style0 from "./Over.vue?vue&type=style&index=0&id=5e7f5bde&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e7f5bde",
  null
  
)

export default component.exports